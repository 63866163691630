


































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CreateETPSchedulePayload, CreateETPSubscriptionPayload} from "@/dto/taxation/individual/estimated/CreateETPSubscriptionPayload";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {ETPScheduleType} from "@/constants/IndividualTaxationConstants";
import ETPService from "@/services/ETPService";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    ETPScheduleType() {
      return ETPScheduleType
    }
  },
  components: {PortalInput, PersonSearch}
})
export default class CreateETP extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  selectedPerson: PersonDTO | null = null;
  payload = new CreateETPSubscriptionPayload();
  newSchedulePayload = new CreateETPSchedulePayload();

  get containsFederal(): boolean {
    return this.payload.schedules.some(s => s.type == ETPScheduleType.FEDERAL);
  }

  add(){
    ifValid(this, () => {
      this.payload.schedules.push(this.newSchedulePayload);
      this.newSchedulePayload = new CreateETPSchedulePayload();
      if(this.containsFederal){
        this.newSchedulePayload.type = ETPScheduleType.STATE;
      }
    })
  }

  onPersonSelected(){
    this.payload.personId = this.selectedPerson!.id;
  }

  create(){
    this.startLoading();
    ETPService.createSubscription(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated()
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }


}
