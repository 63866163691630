



























import {Component, Vue} from 'vue-property-decorator';
import ETPCreate from "@/components/taxation/individual/estimated/ETPCreate.vue";
import {namespace} from "vuex-class";
import ETPService from "@/services/ETPService";
import ETPSubscriptionDTO from "@/dto/taxation/individual/estimated/ETPSubscriptionDTO";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");

@Component({
  components: {PaginationComponent, ETPCreate}
})
export default class ETPSubscriptionList extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  createMode = false;

  subscriptions: Array<ETPSubscriptionDTO> = [];

  page = 1;
  totalPages = 1;
  pageSize = 20;

  mounted() {
    this.loadSubscriptions();
  }

  loadSubscriptions() {
    this.startLoading();
    ETPService.getSubscriptions(this.page, this.pageSize).then(
        ok => {
          this.subscriptions = ok.data.content;
          this.page = ok.data.number + 1;
          this.totalPages = ok.data.totalPages;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  changePage(page: number) {
    this.page = page;
    this.loadSubscriptions();
  }

  goToSubscription(id: number) {
    this.$router.push({name: RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_SUBSCRIPTION, params: {id: `${id}`}});
  }

}
