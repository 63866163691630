import {ETPScheduleType} from "@/constants/IndividualTaxationConstants";

export class CreateETPSubscriptionPayload {
    personId: number | null = null;
    year = 2024;
    schedules: Array<CreateETPSchedulePayload> = [];

    constructor(init?: Partial<CreateETPSubscriptionPayload>) {
        Object.assign(this, init);
    }
}

export class CreateETPSchedulePayload {
    type: ETPScheduleType | null = null;
    state: string | null = null;
    monthlyPayment: number | null = null;

    constructor(init?: Partial<CreateETPSchedulePayload>) {
        Object.assign(this, init);
    }
}